import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Col, DatePicker, Form, Icon, Input, Modal, Radio, Row, Select, Skeleton, Spin, Tooltip } from 'antd'
import BigNumber from 'bignumber.js'
import debounce from 'lodash.debounce'
import Moment from 'moment-timezone'
import { LinkRequestMenu, Permissions } from '../../../constants'
import { clientService, linkRequestService } from '../../../services'
import { fetchAddedLinkRequests, fetchRemovedLinkRequests } from '../../../states/actions/linkRequest'
import { auth, formatter, validator } from '../../../util'

// UI
import { ControlLabel, List, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const pageSize = 40
const totalComparisonFields = 5
const FormItem = Form.Item
const confirm = Modal.confirm
const RadioGroup = Radio.Group
const RadioButton = Radio.Button
const Option = Select.Option

const PucaStatusList = Object.freeze([
  { label: 'Pending', value: 'pending' },
  { label: 'Approved', value: 'approved' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Removed', value: 'removed' },
])
const PucaStatus = Object.freeze(Object.fromEntries(PucaStatusList.map(({ label, value }) => [label, value])))

function getColumns(selectedStatus, onApprove, onReject, onUnlink) {
  const columns = [
    {
      title: 'Portal ID',
      key: 'user_id',
      render: ({ user_id: userId, client_acc_ref: cAccRef, client_ref_id: cRefId }) => (
        <>
          <div>{formatter.toPcpUserId(userId)}</div>

          <div className='wd-list-item-subtitle'>
            <Link rel='noopener noreferrer' target='_blank' to={`/participants/${cRefId}/info`}>{cAccRef}</Link>
          </div>
        </>
      )
    },
    {
      title: 'Name',
      key: 'first_name',
      render: ({ first_name: firstName, last_name: lastName, client_first_name: cFirstName, client_last_name: cLastName }) => (
        <><div>{firstName} {lastName}</div><div className='wd-list-item-subtitle'>{cFirstName} {cLastName}</div></>
      )
    },
    {
      title: 'D.O.B',
      key: 'dob',
      render: ({ dob, client_dob: clientDob }) => (
        <><div>{formatter.toShortDate(dob)}</div><div className='wd-list-item-subtitle'>{formatter.toShortDate(clientDob)}</div></>
      )
    },
    {
      title: 'Email',
      key: 'email',
      render: ({ email, client_email: cEmail }) => (
        <><div>{email}</div><div className='wd-list-item-subtitle'>{cEmail}</div></>
      )
    },
    {
      title: 'Gender',
      key: 'gender',
      render: ({ gender, client_gender: cGender }) => (
        <><div>{formatter.capitalize(gender)}</div><div className='wd-list-item-subtitle'>{formatter.capitalize(cGender)}</div></>
      )
    },
    {
      title: 'NDIS Number',
      key: 'ndis_number',
      render: ({ ndis_number: ndisNumber, client_ndis_number: clientNdisNumber }) => (
        <><div>{ndisNumber}</div><div className='wd-list-item-subtitle'>{clientNdisNumber}</div></>
      )
    }
  ]

  // Do not show status as requested
  // if (selectedStatus === PucaStatus.Removed) {
  //   columns.push({
  //     title: 'Status',
  //     key: 'status',
  //     render: ({ status }) => (
  //       <span className='request-status' style={{ backgroundColor: getStatusColor(status) }}>{formatter.capitalize(status)}</span>
  //     )
  //   })

  //   columns.push({
  //     title: 'Status Date',
  //     key: 'status_date',
  //     render: ({ status_date: statusDate }) => `${formatter.toStandardDate(statusDate)} (${formatter.toDayCount(statusDate)})`
  //   })
  // }

  columns.push({
    title: 'Requested At',
    key: 'created_at',
    render: ({ created_at: createdAt }) => `${formatter.toStandardDate(createdAt)} (${formatter.toDayCount(createdAt)})`
  })

  if (selectedStatus === PucaStatus.Removed) {
    columns.push({
      title: 'Removed At',
      key: 'deleted_at',
      render: ({ deleted_at: deletedAt }) => `${formatter.toStandardDate(deletedAt)} (${formatter.toDayCount(deletedAt)})`
    })
  }

  if (selectedStatus === PucaStatus.Approved || selectedStatus === PucaStatus.Rejected) {
    columns.push({
      title: `${formatter.capitalize(selectedStatus)} At`,
      key: 'status_date',
      render: ({ status_date: statusDate }) => `${formatter.toStandardDate(statusDate)} (${formatter.toDayCount(statusDate)})`
    })
  }

  if (selectedStatus === PucaStatus.Pending || selectedStatus === PucaStatus.Approved) {
    columns.push({
      title: 'Actions',
      key: 'actions',
      render: (record) => selectedStatus === PucaStatus.Pending ? (
        <div className='btn-actions'>
          <Tooltip mouseLeaveDelay={0} title='Approve'>
            <div className='btn-action' onClick={onApprove(record)}>
              <Icon type='check-circle' />
            </div>
          </Tooltip>

          <Tooltip mouseLeaveDelay={0} title='Reject'>
            <div className='btn-action' onClick={onReject(record)}>
              <Icon type='close-circle' />
            </div>
          </Tooltip>
        </div>
      ) : selectedStatus === PucaStatus.Approved ? (
        <div className='btn-actions'>
          <Tooltip mouseLeaveDelay={0} title='Unlink'>
            <div className='btn-action' onClick={onUnlink(record)}>
              <Icon type='delete' />
            </div>
          </Tooltip>
        </div>
      ) : null
    })
  }

  return columns
}

// function getStatusColor(status) {
//   if (status === PucaStatus.Pending) {
//     return '#aaaaaa'
//   } else if (status === PucaStatus.Approved) {
//     return '#3bdb4b'
//   } else if (status === PucaStatus.Rejected) {
//     return '#770000'
//   }
// }

function getStatusDateLabel(status) {
  if (status === PucaStatus.Approved || status === PucaStatus.Rejected) {
    return `${formatter.capitalize(status)} At`
  }

  return 'Status Date'
}

export class LinkRequest extends Component {
  constructor(props) {
    super(props)
    const { linkRequests: { list, total }, loading } = props
    this.state = {
      clients: [],
      clientsLookup: {},
      currentPage: 1,
      disableOtp: false,
      filter: { status: PucaStatus.Pending },
      list,
      loading,
      loadingAction: false,
      pcpUser: {},
      pcpUserShareToken: {},
      searching: false,
      searchText: '',
      selectedClient: {},
      selectedPucaStatus: PucaStatus.Pending,
      selectedRequest: {},
      showLinkModal: false,
      showOtpModal: false,
      similarFieldCount: 0,
      similarFieldPercent: 0,
      total
    }
    this.handleSearch = debounce(this.handleSearch, 500)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { linkRequests: { list, total }, loading } = nextProps
    const state = { ...prevState, list, loading, total }

    if (list !== prevState.list) {
      state.searching = false
    }

    return state
  }

  componentDidMount() {
    const { history } = this.props
    const { currentPage, filter, loading, searchText, sort } = this.state
    const { location } = history
    const { search } = location
    const qs = new URLSearchParams(search)
    const status = qs.get('status')

    if (search && status) {
      const newFilter = { ...filter, status }

      if (status === PucaStatus.Removed) {
        delete newFilter.status
      } else {
        newFilter.status = status
      }

      this.setState({ filter: newFilter, selectedPucaStatus: status }, () => {
        this.fetchLinkRequests({ currentPage, filter: newFilter, loading, searchText, sort })
      })
    } else {
      this.fetchLinkRequests({ currentPage, filter, loading, searchText, sort })
    }

    this.fetchClients()
  }

  hasAccess(accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  renderCompareForm = ({ hideText }) => {
    const { form } = this.props
    const { clients, similarFieldCount, similarFieldPercent } = this.state
    const { getFieldDecorator } = form

    return (
      <>
        <Row gutter={16}>
          <Col>
            <FormItem label='Participant'>
              {getFieldDecorator('client_id', {
                rules: [
                  { required: true, message: 'Please select participant' }
                ]
              })(
                <Select
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, option) => {
                    const children = option.props.children.map((c) => c.props.children.join())
                    return children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }}
                  optionFilterProp="children"
                  onChange={this.changeClient}
                  showSearch
                  style={{ width: '100%' }}
                >
                  {clients.map(({ id, acc_ref: ar, first_name: fn, last_name: ln, dob, email, gender, ndis_number: nn }) => (
                    <Option key={id} value={id}>
                      <div>{fn} {ln} ({ar}){email ? ' - ' : ''}{email}</div>

                      {dob || gender || nn ? <div>{formatter.toShortDate(dob)} {formatter.capitalize(gender)} {nn}</div> : null}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>

        <FormItem label='Name' hasFeedback>
          {getFieldDecorator('pu_name', {
            rules: [
              { validator: this.validatePuName }
            ]
          })(
            <Input readOnly />
          )}
        </FormItem>

        <Row gutter={16}>
          <Col lg={12}>
            <FormItem label='D.O.B' hasFeedback>
              {getFieldDecorator('pu_dob', {
                rules: [
                  { validator: this.validatePuDob }
                ]
              })(
                <Input readOnly />
              )}
            </FormItem>
          </Col>

          <Col lg={12}>
            <FormItem label='Email'>
              {getFieldDecorator('pu_email', {
                rules: [
                  { validator: this.validatePuEmail }
                ]
              })(
                <Input readOnly />
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col lg={12}>
            <FormItem label='Gender'>
              {getFieldDecorator('pu_gender', {
                rules: [
                  { validator: this.validatePuGender }
                ]
              })(
                <Input readOnly />
              )}
            </FormItem>
          </Col>

          <Col lg={12}>
            <FormItem label='NDIS Number'>
              {getFieldDecorator('pu_ndis_number', {
                rules: [
                  { validator: this.validatePuNdisNumber }
                ]
              })(
                <Input readOnly />
              )}
            </FormItem>
          </Col>
        </Row>

        {hideText ? null : (
          <FormItem
            colon={false}
            label={`${similarFieldCount} / ${totalComparisonFields} fields matches (${similarFieldPercent} similarity)`}
            style={{ marginBottom: 0 }}
          />
        )}
      </>
    )
  }

  render() {
    const { form } = this.props
    const {
      clients, currentPage, disableOtp, list, loading, loadingAction, loadingLinkClientId, pcpUser, searching, selectedClient, selectedPucaStatus,
      selectedRequest, showLinkModal, showOtpModal, total
    } = this.state
    const { getFieldDecorator } = form
    const isDisabledLinkButton = (
      !selectedClient || !selectedClient.id || selectedClient.id < 0 ||
      !selectedRequest || !selectedRequest.id || selectedRequest.id < 0
    )
    const isDisabledLinkOtpButton = (
      !pcpUser || !pcpUser.id || pcpUser.id < 0 ||
      !selectedClient || !selectedClient.id || selectedClient.id < 0
    )

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={LinkRequestMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Link Requests' />

          <Page.Filter className='link-request-filter'>
            {this.hasAccess(Permissions.PARTICIPANT.INFO.LIST) ? (
              <Row gutter={8}>
                <Col lg={16}>
                  <ControlLabel>Status</ControlLabel>
                  <RadioGroup value={selectedPucaStatus} onChange={this.changePucaStatus}>
                    {PucaStatusList.map(({ label, value }) => <RadioButton key={value} value={value}>{label}</RadioButton>)}
                  </RadioGroup>
                </Col>

                <Col lg={8}>
                  <div className='btn-box'>
                    <div className='btn' onClick={this.openOtpModal}>Link Portal ID</div>
                  </div>
                </Col>
              </Row>
            ) : null}
          </Page.Filter>

          <br />

          <Page.Filter>
            {this.hasAccess(Permissions.PARTICIPANT.INFO.LIST) ? (
              <Row gutter={8}>
                <Col lg={6}>
                  <ControlLabel>Portal ID, Name, Email, NDIS Number</ControlLabel>
                  <SearchInput placeholder='Search requests' onChange={this.handleSearch} isSearching={searching} />
                </Col>

                {selectedPucaStatus === PucaStatus.Pending ? null : (
                  <Col lg={6}>
                    <ControlLabel>Participant</ControlLabel>
                    <Select
                      allowClear
                      dropdownMatchSelectWidth={false}
                      filterOption={(input, option) => {
                        return option.props.children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                      optionFilterProp="children"
                      showSearch
                      style={{ width: '100%' }}
                      onChange={this.changeParticipant}
                    >
                      {clients.map(({ id, first_name: fn, last_name: ln, acc_ref: ar }) => (
                        <Option key={id} value={id}>{fn} {ln} ({ar})</Option>
                      ))}
                    </Select>
                  </Col>
                )}

                <Col lg={4}>
                  <ControlLabel>Requested At</ControlLabel>
                  <DatePicker allowClear onChange={this.changeCreatedAt} style={{ width: '100%' }} />
                </Col>

                {selectedPucaStatus === PucaStatus.Pending ? null : (
                  <Col lg={4}>
                    <ControlLabel>{getStatusDateLabel(selectedPucaStatus)}</ControlLabel>
                    <DatePicker allowClear onChange={this.changeStatusDate} style={{ width: '100%' }} />
                  </Col>
                )}
              </Row>
            ) : null}
          </Page.Filter>

          <div className='list-link-request'>
            <Skeleton active loading={loading}>
              <Spin spinning={loading}>
                <List
                  cols={getColumns(selectedPucaStatus, this.handleApprove, this.handleReject, this.handleUnlinkClient)}
                  rows={list}
                />

                <Pager
                  current={currentPage}
                  size={pageSize}
                  total={total}
                  totalText={`Total ${total} requests`}
                  onChange={this.changePage}
                  style={{ marginTop: '15px' }}
                />
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>

        <Modal
          title='Link Portal ID'
          visible={showOtpModal}
          cancelButtonProps={{
            loading: loadingAction
          }}
          okButtonProps={{
            disabled: isDisabledLinkOtpButton,
            loading: loadingAction
          }}
          okText='Link'
          onCancel={this.closeOtpModal}
          onOk={this.handleLinkClientOtp}
        >
          <Form>
            <Row gutter={16}>
              <Col lg={12}>
                <FormItem label='Enter code from participant' hasFeedback>
                  {getFieldDecorator('otp', {
                    rules: [
                      { required: showLinkModal, message: 'Please enter code' },
                      { whitespace: showLinkModal, message: 'Please enter code' },
                      { len: 6, message: 'code must be 6 characters' },
                    ]
                  })(
                    <Input className='otp-input' maxLength={6} disabled={disableOtp} />
                  )}
                </FormItem>
              </Col>

              {disableOtp ? null : (
                <Col lg={12}>
                  <FormItem colon={false} label=' '>
                    <Button type='primary' loading={loadingLinkClientId} onClick={this.handleVerifyOtp}>Verify</Button>
                  </FormItem>
                </Col>
              )}
            </Row>

            {pcpUser && pcpUser.id > 0 ? this.renderCompareForm({ hideText: isDisabledLinkOtpButton }) : null}
          </Form>
        </Modal>

        <Modal
          title={`Link Portal ID${selectedRequest.user_id ? ': ' : ''}${formatter.toPcpUserId(selectedRequest.user_id)}`}
          visible={showLinkModal}
          cancelButtonProps={{
            loading: loadingAction
          }}
          okButtonProps={{
            disabled: isDisabledLinkButton,
            loading: loadingAction
          }}
          okText='Link'
          onCancel={this.closeLinkModal}
          onOk={this.handleLinkClient}
        >
          <Form>
            {this.renderCompareForm({ hideText: isDisabledLinkButton })}
          </Form>
        </Modal>
      </Page.Body>
    )
  }

  changeClient = (clientId) => {
    const { form } = this.props
    const { clientsLookup } = this.state
    this.setState({ selectedClient: clientsLookup[clientId] }, () => {
      form.validateFields(['pu_name', 'pu_dob', 'pu_email', 'pu_gender', 'pu_ndis_number'], (errors) => {
        const similarFieldCount = BigNumber(totalComparisonFields).minus(Object.keys(errors).length).toFixed(0)
        const similarFieldPercent = `${BigNumber(similarFieldCount).div(totalComparisonFields).times(100).toFixed()}%`
        this.setState({ similarFieldCount, similarFieldPercent })
      })
    })
  }

  changeCreatedAt = (value) => {
    const { filter, searchText, sort } = this.state

    if (value) {
      filter.created_at = {
        $and: [{ condition: '>=', value: Moment(value).startOf('day') }, { condition: '<=', value: Moment(value).endOf('day') }]
      }
    } else {
      delete filter.created_at
    }

    this.setState({ filter }, () => {
      this.fetchLinkRequests({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  changePage = (currentPage = 1) => {
    this.fetchLinkRequests({ page: currentPage })
  }

  changeParticipant = (value) => {
    const { filter, searchText, sort } = this.state

    if (value) {
      filter.client_id = value
    } else {
      delete filter.client_id
    }

    this.setState({ filter }, () => {
      this.fetchLinkRequests({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  changePucaStatus = (event) => {
    const { filter, searchText, sort } = this.state
    const { target } = event
    const { value } = target

    if (value === PucaStatus.Removed) {
      delete filter.status
    } else {
      filter.status = value
    }

    this.setState({ filter, selectedPucaStatus: value }, () => {
      const qs = new URLSearchParams({ status: value })
      window.history.replaceState(null, '', `/link-requests?${qs.toString()}`)
      this.fetchLinkRequests({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  changeStatusDate = (value) => {
    const { filter, searchText, sort } = this.state

    if (value) {
      filter.status_date = {
        $and: [{ condition: '>=', value: Moment(value).startOf('day') }, { condition: '<=', value: Moment(value).endOf('day') }]
      }
    } else {
      delete filter.status_date
    }

    this.setState({ filter }, () => {
      this.fetchLinkRequests({ currentPage: 1, filter, loading: true, searchText, sort })
    })
  }

  fetchLinkRequests = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.INFO.LIST)) return

    try {
      const { fetchAddedLinkRequests, fetchRemovedLinkRequests } = this.props
      const { selectedPucaStatus } = this.state
      this.setState({ currentPage }, () => {
        selectedPucaStatus === PucaStatus.Pending || selectedPucaStatus === PucaStatus.Approved
          ? fetchAddedLinkRequests({ loading, currentPage, pageSize, filter, sort, searchText })
          : fetchRemovedLinkRequests({ loading, currentPage, pageSize, filter, sort, searchText })
      })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load link requests successfully. Please try again later.')
    }
  }

  fetchClients = async () => {
    if (!this.hasAccess(Permissions.PARTICIPANT.INFO.LIST)) return

    try {
      const clients = await clientService.listAllClients({ pu_id: { condition: 'is null' } })
      this.setState({ clients, clientsLookup: Object.fromEntries(clients.map((client) => [client.id, client])) })
    } catch (e) {
      console.error(e)
    }
  }

  handleApprove = (selectedRequest) => () => {
    const { form } = this.props
    this.setState({ showLinkModal: true, selectedRequest }, () => {
      const { first_name: firstName, last_name: lastName, dob, email, gender, ndis_number: ndisNumber } = selectedRequest
      form.setFieldsValue({
        pu_name: `${firstName} ${lastName}`, pu_dob: formatter.toShortDate(dob), pu_email: email,
        pu_gender: formatter.capitalize(gender), pu_ndis_number: ndisNumber
      })
    })
  }

  handleReject = (selectedRequest) => () => {
    const { filter, loadingAction, searchText, sort } = this.state

    if (loadingAction) {
      return
    }

    confirm({
      title: 'Are you sure you want to reject this request?',
      content: 'Press Ok to continue, Cancel to return',
      onOk: async () => {
        this.setState({ loadingAction: true }, async () => {
          const result = await linkRequestService.rejectLink(selectedRequest.id)
          this.setState({ loadingAction: false }, () => {
            if (result && result.id > 0) {
              this.fetchLinkRequests({ currentPage: 1, filter, loading: true, searchText, sort })
              notify.success('Rejected successfully', 'Link request rejected successfully')
            } else {
              notify.error('Unable to reject successfully', 'Unable to reject link request successfully. Please try again later')
            }
          })
        })
      }
    })
  }

  handleSearch = (value) => {
    const { filter, sort } = this.state
    this.setState({ searching: true }, () => {
      if (!validator.isEmptyString(value)) {
        const dbUserId = formatter.toDBPcpUserId(value)
        const $or = [
          { first_name: { condition: 'ilike', value: `%${value}%` } },
          { last_name: { condition: 'ilike', value: `%${value}%` } },
          { email: { condition: 'ilike', value: `%${value}%` } },
          { ndis_number: { condition: 'ilike', value: `%${value}%` } }
        ]

        if (!validator.isEmptyString(dbUserId)) {
          $or.push({ user_id: { condition: '=', value: dbUserId } },)
        }

        filter.$and = [{ $or }]
      } else {
        delete filter.$and
      }

      this.setState({ searchText: value, currentPage: 1 }, () => {
        this.fetchLinkRequests({ currentPage: 1, filter, loading: true, searchText: (filter.$and ? '' : value), sort })
      })
    })
  }

  handleLinkClient = () => {
    const { form } = this.props
    const { filter, loadingAction, searchText, selectedRequest, sort } = this.state

    if (loadingAction) {
      return
    }

    confirm({
      title: 'Link this Portal ID?',
      content: <>Partcipant will be able to view their profile data in the Client Portal once linked.<br />Press Ok to continue, Cancel to return</>,
      onOk: async () => {
        form.validateFields(['client_id'], async (errors, values) => {
          if (!errors) {
            this.setState({ loadingAction: true }, async () => {
              const { id, pu_id: puId } = selectedRequest
              const result = await linkRequestService.approveLink(id, { ...values, pu_id: puId })

              this.setState({ loadingAction: false }, () => {
                if (result && result.id > 0) {
                  this.closeLinkModal()
                  this.fetchLinkRequests({ currentPage: 1, filter, loading: true, searchText, sort })
                  notify.success('Linked successfully', 'Portal ID linked successfully')
                } else {
                  if (result.invalid && Array.isArray(result.errors) && result.errors[0]) {
                    const { errors } = result

                    if (errors[0].path = 'PMPcpPortalIdExists') {
                      notify.error('Unable to link successfully', 'Portal ID already exists. Please try again with another participant')
                      return
                    }
                  }

                  notify.error('Unable to link successfully', 'Unable to link Portal ID successfully. Please try again later')
                }
              })
            })
          }
        })
      }
    })
  }

  handleUnlinkClient = (selectedRequest) => () => {
    const { filter, loadingAction, searchText, sort } = this.state

    if (loadingAction) {
      return
    }

    confirm({
      title: 'Unlink this Portal ID?',
      content: <>Participant will not be able to view their profile data in the Client Portal once unlinked.<br />Press Ok to continue, Cancel to return</>,
      onOk: async () => {
        this.setState({ loadingAction: true }, async () => {
          const result = await linkRequestService.unlinkClient({
            pu_id: selectedRequest.pu_id, client_id: selectedRequest.client_id
          })
          this.setState({ loadingAction: false }, () => {
            if (result && result.id > 0) {
              this.fetchLinkRequests({ currentPage: 1, filter, loading: true, searchText, sort })
              notify.success('Unlinked successfully', 'Portal ID unlinked successfully')
            } else {
              notify.error('Unable to unlink successfully', 'Unable to unlink Portal ID successfully. Please try again later')
            }
          })
        })
      }
    })
  }

  handleLinkClientOtp = () => {
    const { form } = this.props
    const { loadingLinkClientId, pcpUserShareToken, pcpUser, selectedClient } = this.state

    if (loadingLinkClientId) {
      return
    }

    confirm({
      title: 'Link this Portal ID?',
      content: <>Partcipant will be able to view their profile data in the Client Portal once linked.<br />Press Ok to continue, Cancel to return</>,
      onOk: async () => {
        form.validateFields(['otp'], async (errors) => {
          if (!errors) {
            this.setState({ loadingLinkClientId: true }, async () => {
              const result = await linkRequestService.linkClient({
                pu_id: pcpUser.id, pust_id: pcpUserShareToken.id, client_id: selectedClient.id
              })
              this.setState({ loadingLinkClientId: false }, () => {
                if (result && result.id > 0) {
                  this.closeOtpModal()
                  notify.success('Linked successfully', 'Portal ID linked successfully')
                } else {
                  if (result.invalid && Array.isArray(result.errors) && result.errors[0]) {
                    const { errors } = result

                    if (errors[0].path = 'PMPcpPortalIdExists') {
                      notify.error('Unable to link successfully', 'Portal ID already exists. Please try again with another participant')
                      return
                    }
                  }

                  notify.error('Unable to link successfully', 'Unable to link Portal ID successfully. Please try again later')
                }
              })
            })
          }
        })
      }
    })
  }

  handleVerifyOtp = () => {
    const { form } = this.props
    const { loadingLinkClientId } = this.state

    if (loadingLinkClientId) {
      return
    }

    form.validateFields(['otp'], async (errors, values) => {
      if (!errors) {
        this.setState({ loadingLinkClientId: true }, async () => {
          const result = await linkRequestService.verifyOtp(values)

          if (result && result.pu && result.pust && result.pu.id > 0 && result.pust.id > 0) {
            const { first_name: firstName, last_name: lastName, dob, email, gender, ndis_number: ndisNumber } = result.pu
            this.setState({ disableOtp: true, loadingLinkClientId: false, pcpUser: result.pu, pcpUserShareToken: result.pust }, () => {
              form.setFieldsValue({
                pu_name: `${firstName} ${lastName}`, pu_dob: formatter.toShortDate(dob), pu_email: email,
                pu_gender: formatter.capitalize(gender), pu_ndis_number: ndisNumber
              })
            })
          } else {
            this.setState({ loadingLinkClientId: false }, () => {
              notify.error('Invalid code', 'The code is invalid. Please try again with another code')
            })
          }
        })
      }
    })
  }

  closeLinkModal = () => {
    const { form } = this.props
    const { loadingAction } = this.state

    if (loadingAction) {
      return
    }

    this.setState({ selectedClient: {}, selectedRequest: {}, showLinkModal: false })
    form.resetFields()
  }

  openLinkModal = () => {
    this.setState({ showLinkModal: true })
  }

  closeOtpModal = () => {
    const { form } = this.props
    const { loadingAction } = this.state

    if (loadingAction) {
      return
    }

    this.setState({ disableOtp: false, pcpUser: {}, showOtpModal: false })
    form.resetFields()
  }

  openOtpModal = () => {
    this.setState({ disableOtp: false, showOtpModal: true })
  }

  validatePuName = (rule, value, callback) => {
    const { selectedClient } = this.state
    const { first_name: firstName, last_name: lastName } = selectedClient
    let tokenized = []

    if (typeof firstName === 'string' && firstName.trim().length > 0) {
      tokenized = tokenized.concat(firstName.split(/\s/g))
    }

    if (typeof lastName === 'string' && lastName.trim().length > 0) {
      tokenized = tokenized.concat(lastName.split(/\s/g))
    }

    tokenized = Array.from(new Set(tokenized))
    const puTokenized = Array.from(new Set(value.trim().split(/\s/g)))
    const total = tokenized.length
    let numOfMatch = 0

    for (const item of puTokenized) {
      if (tokenized.indexOf(item) > -1) {
        numOfMatch += 1
      }
    }

    if (numOfMatch >= total) {
      callback()
    } else if (numOfMatch < 1) {
      callback(`Name '${value}' is not the same as '${firstName} ${lastName}'`)
    } else {
      callback(`Name '${value}' is ${BigNumber(numOfMatch).div(total).times(100).toFixed(0)}% similar to '${firstName} ${lastName}'`)
    }
  }

  validatePuDob = (rule, value, callback) => {
    const { selectedClient } = this.state
    const dob = formatter.toShortDate(selectedClient.dob)

    if (value && value !== dob) {
      callback(`D.O.B '${value}' is not the same as '${dob}'`)
    } else {
      callback()
    }
  }

  validatePuEmail = (rule, value, callback) => {
    const { selectedClient } = this.state
    const { email } = selectedClient

    if (value && value !== email) {
      callback(`Email '${value}' is not the same as ${email || "''"}`)
    } else {
      callback()
    }
  }

  validatePuGender = (rule, value, callback) => {
    const { selectedClient } = this.state
    const gender = formatter.capitalize(selectedClient.gender)

    if (value && value !== gender) {
      callback(`Gender '${value}' is not the same as '${gender}'`)
    } else {
      callback()
    }
  }

  validatePuNdisNumber = (rule, value, callback) => {
    const { selectedClient } = this.state
    const { ndis_number: ndisNumber } = selectedClient

    if (value && value !== ndisNumber) {
      callback(`NDIS number '${value}' is not the same as '${ndisNumber}'`)
    } else {
      callback()
    }
  }
}

const mapDispatchToProps = {
  fetchAddedLinkRequests,
  fetchRemovedLinkRequests
}

const mapStateToProps = (state) => {
  return { ...state.LinkRequest }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(LinkRequest))
